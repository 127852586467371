.menu-container {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  position: absolute; 
  top: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  line-height: 80px;
  &.landing {
    background-color: rgba(0, 0, 0, 0);
    &.active{
      background-color: rgba(68, 68, 68, 0.9);
    }
    .menu-mobile  {
      svg {
        color: rgba(68, 68, 68, 0.9);
      }
      &.active {
        svg {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .hide {
    display: none;
    overflow: hidden;
  }
  .menu-links {
    color: #ffff;
    font-size: 1.1em;
    display: inline-flex;
    flex-basis: auto;
    padding-right: 6px;
    letter-spacing: 4px;
    .menu-link {
      padding: 0 4px;
      span {
        cursor: pointer;
        padding: 4px 6px;
        &:hover {
          color: #606060;
          background-color: rgba(255, 255, 255, 0.6);
        }
        &.active {
          color: #606060;
          background-color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .menu-mobile {
      width: 40px; 
      height: 100%;
      &.active {
        svg {
          color: #bcbcbc;
        }
        .menu-mobile-container{
          position: fixed;
          top: 70px;
          left: 0;
          width: 100%;
          background-color: rgba(68, 68, 68, 0.9);
          z-index: 99;
          height: 375px;
          transition: height  0.3s ease-out;
          .menu-mobile-link {
            span {
              &.active {
                color: #606060;
                background-color: rgba(255, 255, 255, 0.6);
              }
            }
          }
        }
      }
      .menu-mobile-container{
        height: 0px;
        overflow: hidden;
      }
      svg {
        width: 40px; 
        height: 70px;
      }
    }
    @media screen and (max-width: 599px) {
      .menu-link {
        display: none;
      }
    }
    @media screen and (min-width: 600px) {
      .menu-mobile {
        display: none;
      }
    }
  }
  &.menu-enter {
    opacity: 0;
  }
  &.menu-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  &.menu-exit {
    opacity: 1;
  }
  &.menu-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
  .menu-logo {
    padding: 5px;
    height: 40px;
  }
}
@media screen and (max-width: 599px) {
  .menu-container {
    height: 70px;
    line-height: 70px;
    .menu-logo {
      padding: 10px;
      height: 50px;
    }
  }
}