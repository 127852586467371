.video-content {
  .title {
    font-family: 'Special Elite', cursive;
    background-color: rgba(247, 247, 247, 0.6);
    padding: 10px;
    font-size: 16px;
  }
  .video {
    min-height: 400px;
    @media screen and (max-width: 959px) {
      min-height: 240px;
    }
    .video-area {
      height: 100%;
      .youtube-player-container {
        height: 100%;
      }
    }
  }
}