.contact-page{
  &.page-wrapper{
    .top-text {
      font-style: italic;
    }
    .email-text {
      height: 100%;
      letter-spacing: 4px;
      .email {
        font-size: 12px;
        font-style: italic;
        color: #666666;
        word-break: break-all;
      }
    }
  }
  .contact-page-container {
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.7);
    .title {
      width: 100%;
      text-align: center;
      font-family: 'Special Elite', cursive;
      font-size: 20px;
      background-color: rgba(179, 179, 179, 0.1);
    }
    .contact-page-grid {
      padding: 16px;
      .item {
        text-align: center;
        .top-text {
          font-style: italic;
        }
        .email-text {
          height: 100%;
          letter-spacing: 4px;
        }
        .email {
          font-style: italic;
          color: #666666;
          word-break: break-all;
        }
        .news-letter {
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
      #mc_embed_signup {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}