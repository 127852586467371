.video-page-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .MuiGrid-spacing-xs-8-23 {
    .MuiGrid-item-2 {
      padding: 0px;
    }
  } 
  .padding-div {
    width: 100%;
    padding: 10px 0;
  }
}