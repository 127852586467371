.live-page-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);
  .title {
    padding: 10px;
    width: 100%;
    text-align: center;
    font-family: 'Special Elite', cursive;
    font-size: 20px;
    background-color: rgba(179, 179, 179, 0.1);
  }
}