.release-content {
  width: 100%;
  min-height:300px;
  .release-panel {
    &.artwork-panel {
      .artwork {
        position: relative;
        height: 100%;
        width: 100%;
        .artwork-area {
          background-color: rgba(61, 61, 61, 0.5);
        }
      }
    }
    &.content-panel {
      .content-container {
        width: 100%;
        background-color: rgba(61, 61, 61, 0.5);
        .content-area {
          height: 100%;
          width: 100%;
          overflow: hidden;
          .video-area {
            height: 100%;
            .youtube-player-container {
              height: 100%;
            }
          }
          .listen-area {
            height: 100%;
            .listen-links {
              padding: 10px;
              height: calc(100% - 50px);
              .item {
                .listen-icon {
                  a {
                    width: 100%;
                    height: 100%;
                    .icon {
                      display: flex;
                      justify-content: center;
                      svg {
                        height: 50px;
                        width: 50px;
                        fill: #e3e3e3;
                        @media screen and (max-width: 959px) {
                          height: 46px;
                          width: 46px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .preOrder-area{
            height: 100%;
            .po-item {
              text-align: center;
              .item {
                padding: 20px;
              }
              .preOrder-link {
                color: #ffff;
                &:hover {
                  color: rgba(255, 255, 255, 0.5);
                }
              }
              .release-date {
                background-color: rgba(255, 255, 255, 0.7);
              }
            }
          }
        }
      }
    }    
  }
}