.landing-page-container{
    display: flex;
    align-items: center;
    .landing-grid {
        max-width: 660px;
        height: 100%;
        margin: 10px auto;
        @media screen and (max-width: 599px) {
            margin: 20px auto;
        }
        &.landing-enter {
            opacity: 0;
        }
        &.landing-enter-active {
            opacity: 1;
            transition: opacity 100ms ease-in;
        }
        &.landing-exit {
            opacity: 1;
        }
        &.landing-exit-active {
            opacity: 0;
            transition: opacity 50ms ease-out;
        }
        .landing-logo{
            background-color: rgba(247, 247, 247, 0.6);
            height:auto;
        }
        .landing-menu-grid {
            background-color: rgba(0, 0, 0, 0.55);
            color: #ffff;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 4px;
            @media screen and (max-width: 599px) {
                display: none;
            }
            .landing-menu {
                .menu-link {
                    text-align: center;
                    cursor: pointer;
                    padding: 8px;
                    &:hover {
                        color: #E5E5E5;
                    }
                }
            }
        }
    }
}