.page-wrapper {
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  bottom: 0;
  width: 100%;
  overflow: auto;
  @media screen and (max-width: 599px) {
    height: calc(100% - 70px);
    top: 70px;
  }
  .footer-grid {
    width: 100%
  }  
  .page-section {
    position: relative;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    &.full-screen {
      min-height: calc(100vh - 150px);
      @media screen and (max-width: 599px) {
        min-height: calc(100vh - 256px);
      }
    }
  }
  @media screen and (max-width: 599px) {
    .page-section {
      width: 100%;
    }
  }
  .image-panel{
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(247, 247, 247, 0.3);
    }
    .panel-background {
      height: 100%; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .title {
      color: white;
      font-weight: bold;
      position: absolute;
      top: calc(50vh - 80px);
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      padding: 20px 40px;
      text-align: center;
      font-size: 3em;
      letter-spacing: 10px;
      font-family: 'Teko', sans-serif;
      @media screen and (max-width: 959px) {
        top: 50%;
      }
    }
  }
  .text-panel {
    height: calc(100% - 30px);
    padding: 20px 10px 10px;
    p {
      padding: 6px 0px;
      margin: 0;
    }
  }
}