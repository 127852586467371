.social-icons {
  position: absolute;
  bottom: 2%;
  right: 2%;
  overflow: hidden;
  .social-icons-grid {
    height: 40px;
    overflow: hidden;
    .social-item{
      text-align: center;
      padding: 8px;
      filter: grayscale(100%) brightness(0.5);
      .share-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        svg {
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          fill-rule: evenodd;
        }
      }
      a {
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
  @media screen and (max-width: 599px) {
    width: 100%;
  }
}
