.footer{
  position: relative;
  padding-top: 30px;
  &.landing {
    position: absolute;
    bottom: 0;
  }
  .footer-copyright {
    font-family: 'Special Elite', cursive;
    position: relative;
    margin: 20px 0;
    @media screen and (max-width: 599px) {
      order: 2;
    }
    .copyright {
      padding-left: 20px;
      position: absolute;
      bottom: 2px;
      overflow: hidden;
      width: 100%;
      span {
        letter-spacing: 4px;
        font-family: sans-serif;
        padding-left: 8px;
      }
      a {
        font-size: 12px;
        &:hover {
          text-decoration: underline
        }
      }
      @media screen and (max-width: 599px) {
        text-align: center;
        padding-left: 0px;
      }
    }
  }
  .footer-icons {
    position: relative;
    height: 40px;
    margin-bottom: 20px;
    text-align: center;
    @media screen and (max-width: 599px) {
      order: 1;
    }
  }
}