.event-item {
  position: relative;
  .item {
    position: relative;
    padding: 20px 10px 10px;
    // text-align: center;
    &.event-info {
      text-align: right;
    }
    &.ticket-link {
      text-align: right;
      padding: 20px 4px 10px 0px;
    }
    .seperator {
      position: absolute;
      right: 0;
      top: 10px;
    }
    a {
      font-family: sans-serif;
      font-size: 14px;
      &:hover {
        color: #757575;
      }
    }
  }
  .border {
    height: 1px;
    background-color: rgba(68, 68, 68, 0.9);
  }
}