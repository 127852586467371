.App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 360px;
    width: 100%;
    position: relative;
    font-family: sans-serif !important;
    a {
        color: inherit;
        text-decoration: none;
    }
    &:after {
        content: "";
        background: url('/images/landing2.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position-x: 80%;
        background-position-y: 86%;
        background-size: cover;
        opacity: 0.7;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
    }
}
